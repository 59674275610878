export default [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
  },
  {
    header: "Waybills",
  },
  {
    title: "Open Waybills",
    route: "transport.open-waybills",
    icon: "ActivityIcon",
  },

  {
    title: "Arrived Waybills",
    route: "transport.arrived-waybills",
    icon: "ArchiveIcon",
  },

  {
    header: "Transactions",
  },
  {
    title: "Open Transactions",
    route: "clearance.open-transactions",
    icon: "LayersIcon",
  },

  {
    title: "Closed Transactions",
    route: "clearance.closed-transactions",
    icon: "LockIcon",
  },

  {
    header: "Accounting",
  },
  {
    title: "Invoices",
    route: "client.invoices",
    icon: "BookmarkIcon",
  },

  // {
  //   title: "Returns",
  //   route: "client.returns",
  //   icon: "CornerRightDownIcon",
  // },

  {
    title: "Account statement",
    route: "client.account-statement",
    icon: "DollarSignIcon",
  },

  {
    header: "Client",
  },

  {
    title: "Log Out",
    route: "client.logout",
    icon: "LogOutIcon",
  },
];
