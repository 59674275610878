import Vue from "vue";
import axios from "axios";

const access_token = JSON.parse(localStorage.getItem("token"));

var subdomain = window.location.host.split(".")[1]
  ? window.location.host.split(".")[0]
  : false;
const baseEnd = "https://" + subdomain + ".optierp.net/api/v1/";
// const baseEnd = "http://" + subdomain + ".optimum.local/api/v1/";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: baseEnd,
  withCredentials: true,
  headers: {
    lang: localStorage.locale,
    Accept: "application/json",
    common: {
      Authorization: `Bearer ${access_token}`,
    },
  },
});

// if we get error 401 which is unauthenticated we redirect to login.

axiosIns.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      window.location = "/login";
    }

    if (error.response.status === 404) {
      window.location = "/error-404";
    }

    if (error.response.status === 403) {
      window.location = "/pages/miscellaneous/not-authorized";
    }

    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
